




















































































import { Vue, Component } from "vue-property-decorator";
import { Order, ProgramOrder } from "@/types";
import axios from "axios";
import { AuthData } from "@/store/auth/types";

@Component
export default class MyOrdersView extends Vue {
  orderSearch = "";
  orderLoading = false;
  orderHeaders = [
    {
      text: "Sipariş ID",
      align: "start",
      filterable: true,
      value: "id"
    },
    { text: "Tarih", value: "dateCreated" },
    { text: "Ücret", value: "amount" },
    { text: "Durum", value: "status", width: "90px" }
  ];
  orders = [] as Order[];

  progOrderSearch = "";
  progOrderLoading = false;
  progOrderHeaders = [
    {
      text: "Sipariş ID",
      align: "start",
      filterable: true,
      value: "id",
      width: "28%"
    },
    { text: "Program Adı", value: "name" },
    { text: "Tarih", value: "dateCreated", width: "190px" },
    { text: "Ücret", value: "amount", width: "110px" },
    { text: "Durum", value: "status", width: "90px" }
  ];
  progOrders = [] as ProgramOrder[];

  get authData(): AuthData {
    return this.$store.getters["auth/data"];
  }

  async getOrders() {
    try {
      this.orderLoading = true;

      var res = await axios.get(`/api/cart/get-orders/${this.authData.email}`, {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.orders = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Sipariş listesi alınamadı.`
      });
    } finally {
      this.orderLoading = false;
    }
  }

  async getProgOrders() {
    try {
      this.progOrderLoading = true;

      var res = await axios.get(`/api/program/get-orders-summary/${this.authData.email}`, {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.progOrders = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Program siparişleri alınamadı.`
      });
    } finally {
      this.progOrderLoading = false;
    }
  }

  async mounted() {
    await this.getOrders();
    await this.getProgOrders();
  }
}
